/*
 *
 * Application Main
 *
 */
!(function(APP) {
    'use strict';


    var breakpoint = 768;

    $.extend(true, APP, {
        breakpoint: breakpoint,
        init: {
            collapse: function() {
                $('.navbar-mobil').on('show.bs.collapse', function () {
                    // do something…
                    $('#menu-mobil').css({ display: 'none' });
                    $('.opened-menu-mobil').css({ display: 'block' });
                    $('.navbar-toggler-icon-close-mobil').css({ display: 'block' });
                });

                $('.navbar-mobil').on('hide.bs.collapse', function () {
                    // do something…
                    $('#menu-mobil').css({ display: 'flex' });
                    $('.opened-menu-mobil').css({ display: 'none' });
                    $('.navbar-toggler-icon-close-mobil').css({ display: 'none' });
                });

                $('.navbar-desktop').on('show.bs.collapse', function () {
                    // do something…
                    $('#navbarOpen').css({ display: 'flex' });
                    $('.navbar-toggler-icon-close').css({ display: 'block' });
                    $('.opened-menu').css({ display: 'none' });
                });

                $('.navbar-desktop').on('hide.bs.collapse', function () {
                    // do something…
                    $('#navbarOpen').css({ display: 'none' });
                    $('.navbar-toggler-icon-close').css({ display: 'none' });
                    $('.opened-menu').css({ display: 'flex' });
                });
            },
            carouselControl: function () {

                var dots = false;
                if ( $('.slick-carousel-box .item').length > 1 ) {
                    dots = true;
                }

                $('.slick-carousel-box').slick({
                    infinite: true,
                    dots: dots,
                    autoplay: true,
                    autoplaySpeed: 7000
                });

                $('.portfolyo-box .carousel').slick({
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 1000,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                infinite: true
                            }
                        }
                    ]
                });
            }
        },

        INIT: function(options) {
            // APP init

            options = options || {};

            var fn;

            for (var i in this.init) {
                if ( Object.prototype.hasOwnProperty.call(this.init, i) && i.charAt(0) !== '_' && typeof(fn = this.init[i]) === 'function' ) {
                    fn.call(this, options && options[i] || options);
                }
            }

            return this;
        }
    });

})(window.APP = window.APP || {});

$(function() {
    'use strict';

    APP.browser = (function() {

        var is = APP.Helper.is,
            val, tmp,
            userAgent = APP.sanitizeXss(navigator.userAgent);

        var browser = {
            mobile: !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(userAgent),
            ios: !!/iPhone|iPad|iPod/i.test(userAgent),
            ie: (tmp = userAgent.toLowerCase().match(/MSIE ([^;]+)|rv:(11)\.[0-9]+/i)) ? parseInt(tmp[1] || tmp[2], 10) : false,
            edge: (tmp = userAgent.indexOf('Edge/')) > 0 ? parseInt(userAgent.substring(tmp + 5, userAgent.indexOf('.', tmp)), 10) : false,
            bp: function() {
                return $(window).width() < APP.breakpoint;
            }
        };

        var $el = $('html'); // document.documentElement

        for (var k in browser ) {
            if ( Object.prototype.hasOwnProperty.call(browser, k) ) {
                val = browser[k];

                if ( val && !is.function(val) ) {
                    $el.addClass(k);
                    if ( !is.boolean(val) ) {
                        $el.addClass(k + val);
                    }
                }
            }
        }

        APP.browser = browser;

        return browser;
    }());

    APP.scriptPath = APP.Helper.getScriptPath(['app.js', 'app.min.js', 'main.js']);

    APP.Helper.loadScript(APP.scriptPath + 'config.js', {
        success: function() {
            APP.INIT(CONFIG);
        },
        failed: function() {
            APP.INIT();
        }
    });
});
